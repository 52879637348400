import {
	Box,
	Dialog,
	DialogContent,
	IconButton,
	Stack,
	Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { MaterialUISwitch } from "../../../components/Switch/Switch";
import ContactFormModalWithButton from "../../Main/ContactFormModalWithButton";
import { useTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { ColorModeContext } from "../../../Theme";
import CloseIcon from "@mui/icons-material/Close";
import { useIsMobile } from "../../../components/Util/Util";
import LogoWithTitle from "./LogoWithTitle";
import { HashLink as Link } from "react-router-hash-link";

const MobileMenu = () => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const theme = useTheme();
	const colorMode = useContext(ColorModeContext);

	return (
		<>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{
					width: "100%",
				}}
			>
				<IconButton
					color="inherit"
					aria-label="logo"
					onClick={handleOpen}
				>
					<MenuIcon fontSize="large" sx={{ color: "navbar.text" }} />
				</IconButton>
				<LogoWithTitle />
				<ContactFormModalWithButton />
			</Stack>
			<Dialog
				open={open}
				onClose={handleClose}
				fullScreen={useIsMobile()}
				fullWidth
				maxWidth="md"
			>
				<DialogContent>
					<Stack
						alignItems="center"
						direction="row"
						justifyContent='flex-start'
					>
						<IconButton
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon fontSize="large" />
						</IconButton>
					</Stack>
					<Box>
						<Box sx={{
							color: "navbar.text",
							display: "flex",
							flexDirection: "column",
							alignItems: "end",
						}}>
							<Link style={{ color: "inherit", textDecoration: "none" }}
								scroll={(el) => {
									el.scrollIntoView(true);
									window.scrollBy(0, -60);
								}}
								to={"/#features"} onClick={handleClose}>
								<Typography variant="h6" component='div'>
									Feature
								</Typography>
							</Link>
							<Link style={{ color: "inherit", textDecoration: "none" }}
								scroll={(el) => {
									el.scrollIntoView(true);
									window.scrollBy(0, -60);
								}}
								to={"/#reviews"} onClick={handleClose}>
								<Typography variant="h6" component='div' sx={{ mt: 2 }}>
									Reviews
								</Typography>
							</Link>
							<Link style={{ color: "inherit", textDecoration: "none" }}
								scroll={(el) => {
									el.scrollIntoView(true);
									window.scrollBy(0, -25);
								}}
								to={"/#about-us"} onClick={handleClose}>
								<Typography variant="h6" component='div' sx={{ mt: 2 }}>
									About
								</Typography>
							</Link>
							<Box sx={{ mt: 2 }}>
								<MaterialUISwitch
									checked={
										theme.palette.mode === "dark"
									}
									onChange={colorMode.toggleColorMode}
									inputProps={{ "aria-label": "controlled" }}
								/>
							</Box>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);

};

export default MobileMenu;
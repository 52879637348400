import { useTheme } from "@mui/material/styles";
import { ReactComponent as LightThemeLogo } from "../../../assets/AmonikaLightThemeLogo.svg";
import { ReactComponent as DarkThemeLogo } from "../../../assets/AmonikaDarkThemeLogo.svg";
import { Box, Link, Typography } from "@mui/material";

const LogoWithTitle = () => {
	const theme = useTheme();

	return (
		<Link href="/" underline="none">
			<Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
				{theme.palette.mode === "dark" ? <DarkThemeLogo /> : <LightThemeLogo />}
				<Typography
					sx={{ color: "navbar.text" }}
					variant="h6"
					component="div"
					fontWeight={"bold"}
				>
					Amonika
				</Typography>
			</Box>
		</Link>
	);
};

export default LogoWithTitle;

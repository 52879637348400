import {Box, Container, Typography} from "@mui/material";
import FAQComponent from "./FAQComponent";
import EHelmet from "../../components/SEO/CustomHelmet";
import BackToHomeLink from "../../components/BackToHome/BackToHomeLink";


const FAQ = [
	{
		summary: "What is web development, and why is it important for my project?",
		details: "Web development refers to the process of creating websites or web applications that are accessible via the internet. Web development is essential for establishing an online presence, reaching a broader audience, and providing valuable information, products, or services to users worldwide. Whether you're launching a new business, promoting your brand, or enhancing customer engagement, web development can play a crucial role in achieving your project goals by providing a platform for interaction, communication, and transactions with your target audience.",
	},
	{
		summary: "What are the key considerations for web development, and how do I choose the right features for my website or web application?",
		details: `When embarking on a web development project, several key considerations come into play, including:
                Target Audience: Identify your target audience and their preferences in terms of device usage, browsing habits, and expectations for user experience.
                Platform and Technology Selection: Choose the right platform(s) and technologies for your website or web application based on factors such as scalability, security, performance, and compatibility. Common platforms include content management systems (CMS) like WordPress, custom development using HTML, CSS, JavaScript, and server-side scripting languages like PHP, Python, or Node.js.
                Features and Functionality: Determine the features and functionality you want to incorporate into your website or web application, keeping in mind user needs, business objectives, and technical feasibility. Common features include responsive design, e-commerce capabilities, content management, social media integration, and user authentication.`,
	},
	{
		summary: "What resources and expertise are needed for successful web development, and how do I ensure a smooth development process?",
		details: `Successfully executing a web development project requires a combination of resources, expertise, and careful planning, including:
                Skilled Development Team: Assemble a team of experienced web developers, designers, and project managers with expertise in front-end and back-end development, user interface (UI) and user experience (UX) design, and web technologies and frameworks.
                Design and User Experience: Incorporate user-centered design principles and best practices to create an intuitive and visually appealing interface and user experience for your website or web application.
                Testing and Quality Assurance: Implement rigorous testing and quality assurance processes to ensure the functionality, performance, and compatibility of your website or web application across different browsers, devices, and screen sizes.
                Deployment and Maintenance: Plan for website deployment to a hosting server and establish processes for ongoing maintenance, updates, and support to address bugs, security vulnerabilities, and user feedback.`,
	},
];

const Web = () => {
	return (
		<Container>

			<EHelmet title='Amonika-Soft Web' description='This is Amonika-Soft Web'>
			</EHelmet>

			<BackToHomeLink />

			<Box
				sx={{
					mt: 6,
				}}
			>
				<Typography
					variant='h1'
					sx={{ fontSize: { xs: "3rem", md: "5rem" } }}
				// fontWeight='bold'
				>
					WEB DEVELOPMENT
				</Typography>
				<Typography
					variant='h6'
					component='div'
					// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
					sx={{
						mt: 2,
					}}
				>
					PrivacyPolicy development services support the creation of all varieties of browser-related software and provide an exclusive boost-up for further business growth. In order to get the desired degree of adoption rates and up-market conversion, contact Amonika Soft — a professional team of software developers, designers, and web engineers.
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 4,
				}}
			>
				<Typography
					variant='h2'
					component='div'
				// sx={{ fontSize: { xs: '3rem', md: '5rem' } }}
				>
					FAQ
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 2,
				}}
			>
				<FAQComponent FAQ={FAQ} />
			</Box>
		</Container>
	);
};

export default Web;
import { Box, Card, CardContent, Rating, Stack, Typography } from "@mui/material";

import ModalCardWithButton from "./ModalCardWithButton";
import { ReviewCardProps } from "./CardBlock";

const StyledCard = (props: ReviewCardProps) => {
	return (
		<Card
			sx={{
				height: "100%",
				border: "1px solid",
				borderColor: "primary.light",
			}}
		>
			<CardContent
				sx={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					position: "relative",
				}}
			>
				<Box
					component="img"
					sx={{
						width: "100px",
						height: "100px",
						position: "absolute",
						top: "1rem",
						right: "1rem",
						objectFit: "cover",
						borderRadius: "50%",
					}}
					alt="Avatar icon"
					src={props.avatarSrc}
				/>
				<Typography variant="h6" component="div" fontWeight="bold">
					The reviewer
				</Typography>
				<Typography gutterBottom variant="h5" component="div" color="text.secondary">
					{props.reviewer}
				</Typography>
				<Typography variant="h6" component="div" fontWeight="bold">
					The project
				</Typography>
				<Typography gutterBottom variant="h5" component="div" color="text.secondary">
					{props.project}
				</Typography>
				<Typography variant="h6" component="div" fontWeight="bold">
					Feedback summary
				</Typography>
				<Typography gutterBottom variant="body1" component="div" color="text.secondary">
					{props.feedbackSummary}
				</Typography>
				<Stack
					justifyContent='space-between'
					alignItems='center'
					direction='row'
					sx={{
						mt: "auto"
					}}
				>
					<Rating
						value={5}
						readOnly
						sx={{
							// color: 'primary.light'
						}}
					/>
					<ModalCardWithButton {...props} />
				</Stack>
			</CardContent>
		</Card>
	);
};

export default StyledCard;
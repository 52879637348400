import { AppBar, Container, Stack, Toolbar } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
import { useIsMobile } from "../../../components/Util/Util";
import { useEffect, useState } from "react";


const Navbar = () => {
	const [scrollPosition, setPosition] = useState(0);

	useEffect(() => {
		function updatePosition() {
			const heightOfMainPage = (document.documentElement.scrollHeight - document.documentElement.clientHeight) / 100;
			console.log(heightOfMainPage);
			setPosition(window.scrollY / heightOfMainPage);
		}

		window.addEventListener("scroll", updatePosition);
	}, []);


	return (
		<AppBar
			position="sticky"
			sx={{
				backgroundColor: "navbar.bgBlur",
				backdropFilter: "blur(10px)",
			}}
		>
			<Container>
				<Toolbar
					disableGutters={useIsMobile()}
					sx={{
						minHeight: {
							md: "80px",
						}
					}}
				>
					<Stack
						direction='row'
						justifyContent='space-between'
						sx={{
							width: "100%",
						}}
					>
						{useIsMobile() ? <MobileMenu /> : <DesktopMenu />}
					</Stack>
				</Toolbar>
			</Container>
			<LinearProgress
				color={"inherit"}
				variant="determinate"
				sx={{
					":before": {
						backgroundColor: "unset"
					},
					color: "navbar.button.bg",
				}}
				valueBuffer={100}
				value={scrollPosition} />
		</AppBar>
	);
};
export default Navbar;

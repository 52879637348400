import Container from "../../components/Atoms/Container";
import { Outlet } from "react-router-dom";
import Navbar from "../Generic/Navbar";

import FormContactUs from "./ContactsField";
import { Footer } from "../../components/Footer/Footer";

const LayoutMain = () => (
	<div>
		<Navbar />
		<Container
			maxWidth={false}
			disableGutters
			sx={{ overflow: "hidden" }}
		>
			<Outlet />
			<FormContactUs />
			<Footer />
		</Container>
	</div>
);

export default LayoutMain;
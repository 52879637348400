import { Box, Container, Typography } from "@mui/material";
import EHelmet from "../../components/SEO/CustomHelmet";
import BackToHomeLink from "../../components/BackToHome/BackToHomeLink";
import { Footer } from "../../components/Footer/Footer";
import Navbar from "../../layouts/Generic/Navbar";


const TermsOfUse = () => {
	return (
		<>
			<Navbar />
			<Container disableGutters={true}>

				<EHelmet title='Amonika-Soft Terms of Use' description='This is Amonika-Soft Terms of Use'>
				</EHelmet>

				<BackToHomeLink />

				<Box
					sx={{
						mt: 6,
					}}
				>
					<Typography
						variant='h1'
						sx={{ fontSize: { xs: "3rem", md: "5rem" } }}

					>
						Terms of Use
					</Typography>

					<Typography
						variant='h5'
					>
						1. Acceptance of the Terms of Service
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						By accessing, linking to, or using this site you confirm your acceptance of these Terms of
						Service (the “TOS”). Amonika-Soft Inc. (“Amonika-Soft”) reserves, and shall have, the right to
						amend, remove, or add to the TOS at any time. Amonika-Soft shall make you aware of such
						amendments
						which shall be effective immediately. Your access, link to, or use of the Services (as defined
						below), after the effective date of the amended TOS will constitute your acceptance of the TOS,
						as amended.
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						2. Description of Services and License
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						Amonika-Soft’s website features software products and solutions developed by Amonika-Soft for
						its
						customers (the “Services”). You acquire absolutely no rights or licenses in or to the Services
						other than the limited right to utilize the Services in accordance with these TOS.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						3. Third-Party Content
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						As part of the Services, Amonika-Soft may occasionally link to, or promote websites, services,
						content or data from other companies or offer the user the ability to download digital files
						created by other companies (“Third Party Content”). Amonika-Soft takes rigorous steps to verify
						and validate the truthfulness and accuracy of the information contained in the Third-Party
						Content, however, the Third Party Content is ultimately under the control and is the sole
						responsibility, of the owners and operators of such other companies.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						4. Use of Services and Restrictions on Use
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						<p> You agree that you shall not use the Services for any illegal purpose or in any manner not
							consistent with the TOS. You may not use the Services for any illegal purpose, for the
							facilitation of the violation of any law or regulation, or in any manner inconsistent with
							these
							TOS. You agree to use the Services solely for your own use and benefit, and not for resale
							or
							other transfer or disposition to, or use by or for the benefit of, any other person or
							entity.</p>
						<p> Except as expressly permitted by us in writing you may not copy, reproduce, recompile,
							decompile, disassemble, reverse engineer, distribute, publish, display, perform, modify,
							upload
							to, create derivative works from, transmit, transfer, sell, license, upload, edit post,
							frame,
							link, or in any way exploit any part of the Services, except that you may download material
							from
							the Services and/or make one print copy for your own personal use, provided that you retain
							all
							copyright and other proprietary notices.</p>
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						5. Indemnity
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						You agree to defend, indemnify and hold harmless, Amonika-Soft and affiliates together with their
						respective employees, agents, directors, and officers from and against all the liabilities,
						claims, damages and expenses, including reasonable attorneys’ fees and costs, arising out of
						your use of the Services, any breach or alleged breach of the TOS or any infringement of the
						copyright, trademark, service marks, proprietary or other rights of any third parties to the
						extent that the liability, claims, damages and expenses are a result of negligence or willful
						misconduct on your part.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						6. Proprietary Rights
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						You acknowledge and agree that the Services and any software used in connection with the
						Services contain proprietary and confidential information that is protected by applicable
						intellectual property and other laws. You further acknowledge and agree that Third Party Content
						is protected by copyrights, trademarks, service marks, patents or other proprietary rights and
						laws. You further agree not to resell or distribute any of the said protected information or
						Third Party Content to any third party or to use it in any way which may cause any kind of
						damage to Amonika-Soft or infringe on the property rights of owners of such protected information
						or Third Party Content.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						7. Disclaimer
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
						<ul>
							<li>YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK AND ACKNOWLEDGE THAT THE SERVICES,
								INCLUDING, BUT NOT LIMITED TO, CONTENT, SERVICES, GOODS, OR ADVERTISEMENTS ARE PROVIDED
								“AS IS” AND “AS AVAILABLE”. YOU FURTHER AGREE THAT Amonika-Soft MAKES NO WARRANTY OF ANY
								KIND, EXPRESS OR IMPLIED, AS TO THE SERVICE, INCLUDING, BUT NOT LIMITED TO,
								MERCHANTABILITY, NON-INFRINGEMENT, TITLE, OR FITNESS FOR A PARTICULAR PURPOSE OR USE.
							</li>
							<li>Amonika-Soft MAKES NO WARRANTY THAT (A) THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE
								UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, (B) THE RESULTS THAT MAY BE OBTAINED FROM
								THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, (C) THE QUALITY OF ANY PRODUCTS,
								SERVICES, INFORMATION OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE
								SERVICES WILL MEET YOUR EXPECTATIONS, OR (D) ANY ERRORS IN THE SOFTWARE WILL BE
								CORRECTED.
							</li>
							<li>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS
								ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND
								HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR
								COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD OR DISPLAY DEVICE, OR LOSS OF DATA THAT
								RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
							</li>
							<li>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM Amonika-Soft OR
								THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS.
							</li>
							<li>Limitation of Liability</li>
						</ul>
						TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAWS, NEITHER PARTY HERETO, NOR ITS RESPECTIVE
						SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS SHALL BE
						LIABLE TO THE OTHER PARTY HERETO, OR ANY THIRD PARTY, FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
						CONSEQUENTIAL OR EXEMPLARY DAMAGES, EVEN IF SUCH DAMAGES ARE REASONABLY FORESEEABLE OR SUCH
						PARTY OTHERWISE WAS INFORMED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING, BUT NOT LIMITED
						TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES RESULTING
						FROM ANY USE OF THIS SITE AND SERVICES. EXCEPT FOR FEES DUE AND OWING BY YOU FOR THE
						SERVICES AND THE INDEMNIFICATION, NEITHER Amonika-Soft NOR YOU SHALL BE LIABLE FOR DAMAGES IN
						EXCESS OF, AND LIABILITY SHALL BE LIMITED TO USD $100.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						8. Termination
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						You agree that Amonika-Soft, in its sole discretion, may terminate, change or limit all or any of
						the Services, or their availability to you, notice at any time and without prior notice, with or
						without cause. If Amonika-Soft terminates any of the Services without cause, Amonika-Soft shall
						refund on a pro-rata basis any pre-paid unused fees for such terminated Services. You agree that
						you may terminate the agreement for convenience at any time without prior written notice, with
						or without cause. If you terminate the agreement without cause, all subscription fees already
						paid for future periods will be forfeited. Any unpaid fees for services already performed will
						be payable immediately.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						9. Copyrights and Copyright Agent
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						Amonika-Soft respects the rights of all copyright holders and has implemented a policy that
						provides for the protection of the rights of copyright holders. If you believe that your work
						has been used in a way that constitutes copyright infringement, please provide Amonika-Soft’s
						Copyright Agent with the following information required by the Online Copyright Infringement
						Liability Limitation Act of the Digital Millennium Copyright Act, 17 U.S.C. 512:
						<ul>
							<li>A physical or electronic signature of a person authorized to act on behalf of the owner
								of an exclusive right that is allegedly infringed;
							</li>
							<li>Identification of the copyrighted work claimed to have been infringed, or, if multiple
								copyrighted works at a single online site are covered by a single notification, a
								representative list of such works at that site;
							</li>
							<li>Identification of the material that is claimed to be infringing or to be the subject of
								infringing activity and that is to be removed or access to which is to be disabled, and
								information reasonably sufficient to permit us to locate the material;
							</li>
							<li>Information reasonably sufficient to permit us to contact the complaining party;</li>
							<li>A statement that the complaining party has a good faith belief that use of the material
								in the manner complained of is not authorized by the copyright owner, its agent, or the
								law;
							</li>
							<li>A statement that the information in the notification is accurate, and under penalty of
								perjury, that the complaining party is authorized to act on behalf of the owner of an
								exclusive right that is allegedly infringed.
							</li>
						</ul>

						For copyright inquiries under the Digital Millennium Copyright Act please contact:
						<br />
						Amonika-Soft Inc.
						<br />
						Email:
						contact-team@amonika-soft.com
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						10. Entire Agreement
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						This TOS constitutes the entire understanding and agreement and supersedes any prior or
						contemporaneous agreements between you and Amonika-Soft with respect to the subject matter hereof.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						11. Headings
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						The descriptive headings contained in these TOS are for convenience only and shall not affect
						the meaning or interpretation of these TOS.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						12. Partial Invalidity
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						If any provision of these TOS is invalid, such invalidity shall not in any way affect the
						validity and enforceability of all the other terms and conditions of the TOS.
					</Typography>
					<br />

					<Typography
						variant='h5'
					>
						13. Governing Law
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						These TOS shall be governed by, and construed in accordance with, the laws of the State of
						Wyoming.
					</Typography>
					<br />
				</Box>

			</Container>
			<Footer />
		</>
	);
};

export default TermsOfUse;

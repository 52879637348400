import { Box, Typography } from "@mui/material";

import cube from "../../assets/cube.png";


const AboutUsSection = () => {
	return (
		<Box sx={{
			color: "text.primary",
			mt: 2,
			display: "flex",
			flexDirection: {
				xs: "row",
				md: "column"
			},
			justifyContent: "space-around",
			alignItems: "center",

			minHeight: "100vh",
			position: "relative",

		}}>
			{/* Floating Cubes */}
			<Box
				sx={{
					position: "absolute",
					top: { xs: "0", sm: "0", md: "0" },
					left: { xs: "-220px", sm: "-180px", md: "-300px" },
					zIndex: -1,
				}}
			>
				<Box
					component="img"
					sx={{
						width: "100%",
						maxWidth: { xs: 300, md: 500 },
					}}
					alt="Cube"
					src={cube}
				/>
			</Box>
			<Box
				sx={{
					position: "absolute",
					bottom: { xs: "0", sm: "0", md: "0" },
					right: { xs: "-220px", sm: "-180px", md: "-300px" },
					zIndex: -1,
				}}
			>
				<Box
					component="img"
					sx={{
						width: "100%",
						maxWidth: { xs: 300, md: 500 },
					}}
					alt="Cube"
					src={cube}
				/>
			</Box>
			{/* /End Floating Cubes */}

			{/* Background Blur for mobile */}
			<Box
				sx={{
					width: "100%",
					height: "100%",
					borderRadius: 4,
					position: "absolute",
					top: 0,
					left: 0,
					backgroundColor: "rgba(255, 255, 255, 0.3)",
					backdropFilter: "blur(10px)",
					zIndex: -1,
					display: {
						xs: "block", sm: "none"
					}
				}}
			>
			</Box>

			{/* About us text */}
			<Box
				sx={{
					maxWidth: "800px",
					width: { xs: "100%", md: "100%" },
					margin: 2,
				}}
			>
				{/* Background circle */}
				{/* <Box
          sx={{
            backgroundColor: 'heroBuble.primary',
            borderRadius: '50%',
            fontSize: {
              xs: '150px',
              md: '300px'
            },
            height: '2em',
            width: '2em',
            filter: 'blur(72px)',

            position: 'absolute',
            top: { xs: '-0.2em', md: '-0.5em' },
            left: { xs: '0.2em', md: '-0.5em' },
            zIndex: -1,
          }}
        >
        </Box> */}

				<Typography
					variant='h2'
					// fontWeight='bold'
					sx={{ textAlign: "center", }}
				>
					About us
				</Typography>
				<Typography
					variant='body1'
					component='div'
					sx={{
						fontSize: {
							xs: 16,
							md: 20,
						}
					}}
				>

					<Box
						sx={{
							mt: 2,
						}}
					>
						👋  Welcome to Amonika Soft, where innovation meets excellence in software development.
						We are a passionate team of professionals dedicated to crafting cutting-edge solutions that empower businesses and individuals to thrive in the digital age.
					</Box>
					<Box
						sx={{
							mt: 2,
						}}
					>
						We come from Ukraine 🇺🇦
						and our approach to software development is rooted in collaboration 🤝, transparency ✨, and integrity.
						We work closely with our clients every step of the way, from initial concept to final delivery, ensuring that their vision is brought to life with precision and care.
					</Box>
					<Box
						sx={{
							mt: 2,
						}}
					>
						Whether you&apos;re looking to build a custom web application, develop a mobile app, or modernize your existing systems, our team has the skills and experience to turn your ideas into reality.
						We thrive on challenges and are committed to delivering solutions that exceed expectations, on time and within budget. 💼💻
					</Box>
					<Box
						sx={{
							mt: 2,
						}}
					>
						Thank you for considering Amonika Soft for your software development needs.
						We look forward to the opportunity to work with you and help you achieve your goals. 🚀🌟
					</Box>

				</Typography>

			</Box>
		</Box>
	);
};

export default AboutUsSection;
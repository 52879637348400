import "./App.css";
import Theme from "./Theme";

import { Route, Routes } from "react-router-dom";
import Content from "./components/Content";
import { Box } from "@mui/material";


function App() {
	return (
		<Theme>
			<Box className="App" >
				<Routes>
					<Route path="*" element={<Content />} />
				</Routes>
			</Box>
		</Theme>
	);
}

export default App;

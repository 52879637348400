import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, PaletteMode, useMediaQuery } from "@mui/material";

type ThemeContextType = {
	mode: string
	toggleColorMode: () => void
}
const ColorModeContext = createContext<ThemeContextType>({} as ThemeContextType);

const getDesignTokens = (mode: PaletteMode) => ({
	palette: {
		mode,
		...(mode === "light"
			? {
				// palette values for light mode
				primary: {
					main: "#343434"
				},
				divider: "#000",
				background: {
					default: "#FBFBFF",
					paper: "#FBFBFF",
				},
				text: {
					primary: "#343434",
					secondary: "#454444",
				},
				navbar: {
					primary: "#F2F1FF",
					bg: "#F2F1FF",
					bgBlur: "rgba(242, 241, 255, 0.3)",
					text: "#000000",
					button: {
						bg: "#535B9E",
						hover: "#608BE0",
						text: "#FFFFFF",
					}
				},
				hero: {
					bg: "#fff",
					buble: "#FCD8FF",
				},
				shadows: {
					card: "#D4B3FF"
				},
				contactForm: {
					bgModal: "#FBFBFF",
					buble: "#E7D6FF",
				},
			}
			: {
				// palette values for dark mode
				primary: {
					main: "#FBFBFF"
				},
				divider: "#fff",
				background: {
					default: "#3A3A3A",
					paper: "#383838",
				},
				text: {
					primary: "#FBFBFF",
					secondary: "#DFDFDF",
				},
				navbar: {
					primary: "#2B2B2B",
					bg: "#2B2B2B",
					bgBlur: "rgba(43, 43, 43, 0.3)",
					text: "#FFFFFF",
					button: {
						bg: "#535B9E",
						hover: "#608BE0",
						text: "#FFFFFF",
					}
				},
				hero: {
					bg: "#000",
					buble: "#7B6194"
				},
				shadows: {
					card: "#8C95E4"
				},
				contactForm: {
					bgModal: "#3A3A3A",
					buble: "#4D60C2",
				},
			}),
	},
});


type ThemeProps = {
	children: ReactNode
}

export default function ToggleColorMode(props: ThemeProps) {
	const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
	const preferedMode = prefersDarkMode ? "dark" : "light";

	const [mode, setMode] = useState<PaletteMode>(preferedMode);

	useEffect(() => {
		const stored = localStorage.getItem("themeMode");
		setMode(stored ? JSON.parse(stored) : preferedMode);

	}, [preferedMode]);

	function updateTheme(theme: PaletteMode) {
		setMode(theme);
		localStorage.setItem("themeMode", JSON.stringify(theme));
	}

	const toggleColorMode = () => {
		switch (mode) {
		case "light":
			updateTheme("dark");
			break;
		case "dark":
			updateTheme("light");
			break;
		default:
		}
	};

	const theme = useMemo(
		() =>
			createTheme(
				getDesignTokens(mode)
			),
		[mode],
	);

	return (
		<ColorModeContext.Provider value={{ mode, toggleColorMode }}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{props.children}
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export { ColorModeContext };
import { Box, Button, IconButton, Stack, SwipeableDrawer, Typography, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import { useState } from "react";
import ModalCard from "./ModalCard";
import { ReviewCardProps } from "./CardBlock";

const ReviewCardModalWithButton = (props: ReviewCardProps) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);


	return (
		<>
			<Button
				variant="text"
				sx={{
					color: "text.secondary",
					fontWeight: "bold",
					textTransform: "none",
					borderRadius: 4
				}}
				onClick={handleOpen}
			>
				Read more
				<ArrowCircleRightOutlinedIcon sx={{ ml: 1 }} />
			</Button>
			<SwipeableDrawer
				anchor='right'
				open={open}
				onClose={handleClose}
				onOpen={handleOpen}
			>
				<Box
					sx={{
						padding: 2,
						height: "100%",
					}}
					role="presentation"
				>
					<Stack
						alignItems="center"
						direction="row"
						justifyContent='space-between'
					>
						<Typography variant="h4" fontWeight="bold">Review</Typography>

						<IconButton
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon fontSize="large" />
						</IconButton>
					</Stack>

					<ModalCard {...props} />
				</Box>
			</SwipeableDrawer>
		</>
	);
};

export default ReviewCardModalWithButton;

import {Box, Container, Typography} from "@mui/material";
import FAQComponent from "./FAQComponent";
import EHelmet from "../../components/SEO/CustomHelmet";
import BackToHomeLink from "../../components/BackToHome/BackToHomeLink";


const FAQ = [
	{
		summary: "What exactly does \"From Scratch\" development entail, and how is it different from other development approaches?",
		details: "\"From Scratch\" development refers to building a software application or system entirely from the ground up, without relying on pre-existing frameworks, templates, or codebases. This approach involves creating custom solutions tailored to the specific needs and requirements of the project. Unlike using off-the-shelf solutions or modifying existing codebases, \"From Scratch\" development offers complete control and flexibility over the development process and final product. However, it may require more time, resources, and expertise compared to other development approaches.",
	},
	{
		summary: "What are the advantages of opting for \"From Scratch\" development for my project?",
		details: `"Choosing "From Scratch" development offers several advantages, including:
            Customization: "From Scratch" development allows for complete customization of the software solution to meet the unique needs and requirements of your project.
            Scalability: Since the solution is built from the ground up, it can be designed with scalability in mind, allowing for future expansion and growth as your project evolves.
            Performance Optimization: Building the solution from scratch enables developers to optimize performance, minimize bloat, and eliminate unnecessary dependencies, resulting in a leaner and more efficient product.
            Intellectual Property: With "From Scratch" development, you retain full ownership and control over the codebase and intellectual property rights associated with the software solution.`,
	},
	{
		summary: "What resources and expertise are required for successful \"From Scratch\" development?",
		details: `Successfully embarking on a "From Scratch" development project requires a combination of resources, expertise, and careful planning, including:
            Skilled Development Team: It is essential to assemble a team of experienced developers with expertise in the programming languages, technologies, and frameworks relevant to your project.
            Clear Requirements and Vision: Clearly define the project requirements, goals, and objectives upfront to guide the development process and ensure alignment with stakeholder expectations.
            Time and Budget Allocation: Allocate sufficient time and resources for the development process, considering factors such as project complexity, scope, and timeline.
            Testing and Quality Assurance: Implement robust testing and quality assurance processes to validate the functionality, performance, and reliability of the software solution throughout the development lifecycle.
            Communication and Collaboration: Foster open communication and collaboration between stakeholders, developers, and other project team members to ensure transparency, address challenges, and facilitate decision-making.`,
	},
];

const FromScratch = () => {
	return (
		<Container>

			<EHelmet title='Amonika-Soft FromScratch' description='This is Amonika-Soft FromScratch'>
			</EHelmet>

			<BackToHomeLink />

			<Box
				sx={{
					mt: 6,
				}}
			>
				<Typography
					variant='h1'
					sx={{ fontSize: { xs: "3rem", md: "5rem" } }}
				// fontWeight='bold'
				>
					From Scratch DEVELOPMENT
				</Typography>
				<Typography
					variant='h6'
					component='div'
					// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
					sx={{
						mt: 2,
					}}
				>
					From Scratch development services support the creation of all varieties of browser-related software and provide an exclusive boost-up for further business growth. In order to get the desired degree of adoption rates and up-market conversion, contact Amonika Soft — a professional team of software developers, designers, and web engineers.
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 4,
				}}
			>
				<Typography
					variant='h2'
					component='div'
				// sx={{ fontSize: { xs: '3rem', md: '5rem' } }}
				>
					FAQ
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 2,
				}}
			>
				<FAQComponent FAQ={FAQ} />
			</Box>
		</Container>
	);
};

export default FromScratch;
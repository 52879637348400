import {Route, Routes} from "react-router-dom";
import LayoutMain from "../layouts/Main";
import LayoutError from "../layouts/Error";
import Home from "../views/Home";
import Web from "../views/FeaturePages/Web";
import Mobile from "../views/FeaturePages/Mobile";
import Design from "../views/FeaturePages/Design";
import FromScratch from "../views/FeaturePages/FromScratch";
import DevOps from "../views/FeaturePages/DevOps";
import PrivacyPolicy from "../views/Privacy/PrivacyPolicy";
import TermsOfUse from "../views/Privacy/TermsOfUse";

const Router = () => (
	<Routes>
		<Route path="/" element={<LayoutMain/>}>
			<Route index element={<Home/>}/>
			<Route path='/web' element={<Web/>}/>
			<Route path='/mobile' element={<Mobile/>}/>
			<Route path='/design' element={<Design/>}/>
			<Route path='/fromscratch' element={<FromScratch/>}/>
			<Route path='/devops' element={<DevOps/>}/>
		</Route>
		<Route path='/privacy/policy' element={<PrivacyPolicy/>}/>
		<Route path='/privacy/terms-of-use' element={<TermsOfUse/>}/>
		<Route path="*" element={<LayoutError/>}/>
	</Routes>
);

export default Router;
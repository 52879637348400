import { Card, CardContent, Typography } from "@mui/material";

type FeatureCardProps = {
	title: string;
	description: string;
};

const StyledCard = (props: FeatureCardProps) => {
	return (
		<Card
			sx={{
				color: "shadows.card",
				height: "100%",
				":hover": {
					boxShadow: "0 0px 30px",
				},
			}}
		>
			<CardContent>
				<Typography
					gutterBottom
					component="div"
					sx={{
						color: "text.secondary",
						opacity: 0.6,
						width: 500,
						whiteSpace: "nowrap",
						overflow: "hidden",
						fontSize: 100,
						m: 0,
						background: "-webkit-linear-gradient( 360deg, #DB93FF, #5DC5FF)",
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent",
					}}
				>
					{props.title}
				</Typography>

				<Typography
					gutterBottom
					variant="h5"
					component="div"
					color="text.primary"
				>
					{props.title}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{props.description}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default StyledCard;

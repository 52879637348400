import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link, Typography } from "@mui/material";

const BackToHomeLink = () => {
	return (
		<Box
			sx={{
				display: "flex",
			}}
		>
			<Link href="/" underline="none">
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						mt: 2,
					}}
				>
					<ArrowBackIcon fontSize="small" />
					<Typography variant="subtitle1" component="div" sx={{ ml: 0.5 }}>
						Back
					</Typography>
				</Box>
			</Link>
		</Box>
	);
};

export default BackToHomeLink;

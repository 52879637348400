import { Box, Divider, Rating, Stack, Typography } from "@mui/material";

import ApartmentIcon from "@mui/icons-material/Apartment";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { ReviewCardProps } from "./CardBlock";

const StyledCard = (props: ReviewCardProps) => {
	return (
		<Box
			sx={{
				width: {
					xs: "auto",
					md: 450,
				}
			}}
		>
			<Box>
				<Stack alignItems='center' direction='row' sx={{ mb: 2 }}>
					<Box
						component="img"
						sx={{
							width: "60px",
							height: "60px",
							// position: "absolute",
							// top: "1rem",
							// right: "1rem",
							objectFit: "cover",
							borderRadius: "50%",
						}}
						alt="Avatar icon"
						src={props.avatarSrc}
					/>
					<Box
						sx={{
							ml: 1,
						}}
					>
						<Typography variant="h6" component="div" fontWeight="bold" sx={{ textTransform: "uppercase" }}>
							The reviewer
						</Typography>
						<Typography variant="body1" component="div" color="text.secondary">
							{props.reviewer}
						</Typography>
					</Box>
				</Stack>
				<Box
					sx={{
						fontSize: { xs: 20, md: 24 },
						color: "text.secondary",
					}}
				>
					<Stack justifyContent='space-between' alignItems='center' direction='row' sx={{ mb: 1 }}>
						<Stack alignItems='center' direction='row' sx={{ mr: 1 }}>
							<ApartmentIcon
								sx={{
									mr: 1,
								}}
								fontSize='large'
							/>
							<Typography
								component="div"
								sx={{
									textAlign: "center",
									fontSize: "inherit"
								}}
							>
								Software
							</Typography>
						</Stack>
						<Stack alignItems='center' direction='row'>
							<LocationOnIcon
								sx={{
									mr: 1,
								}}
								fontSize='large'
							/>
							<Typography
								component="div"
								sx={{
									textAlign: "center",
									fontSize: "inherit"
								}}
							>
								Ukraine
							</Typography>
						</Stack>
					</Stack>

					<Stack justifyContent='space-between' alignItems='center' direction='row'>
						<Stack alignItems='center' direction='row' sx={{ mr: 1 }}>
							<PersonIcon
								sx={{
									mr: 1,
								}}
								fontSize='large'
							/>
							<Typography
								component="div"
								sx={{
									textAlign: "center",
									fontSize: "inherit"
								}}
							>
								11-50 Employees
							</Typography>
						</Stack>
						<Stack alignItems='center' direction='row'>
							<CheckCircleIcon
								sx={{
									mr: 1,
								}}
								fontSize='large'
							/>
							<Typography
								component="div"
								sx={{
									textAlign: "center",
									fontSize: "inherit"
								}}
							>
								Verified
							</Typography>
						</Stack>
					</Stack>
				</Box>

				<Typography sx={{ mt: 2, textTransform: "uppercase" }} variant="h6" component="div" color="text.secondary" >
					The project
				</Typography>
				<Typography gutterBottom variant="h5" component="div" fontWeight="bold">
					{props.projectName}
				</Typography>

				<Stack alignItems='center' justifyContent='flex-end' direction='row' sx={{ mb: 2 }}>
					<CalendarMonthIcon
						sx={{
							mr: 1,
						}}
						fontSize='medium'
					/>
					<Typography variant="body1" component="div" color="text.secondary" >
						{props.projectDate}
					</Typography>

				</Stack>

				<Divider />

				<Rating
					value={5}
					readOnly
					sx={{
						// color: 'primary.light'
						mt: 2,
						mb: 2,
					}}
				/>
				{/* <Stack justifyContent='space-between' alignItems='flex-start' direction='row'>
					<Box
						sx={{
							mr: 2,
							width: "55%",
						}}
					>

						<Typography gutterBottom variant="body1" component="div" color="text.secondary">
							{props.reviewText}
						</Typography>
					</Box>
					<Box
						sx={{
							fontSize: { xs: 16, md: 16 }
						}}
					>
						<Typography component="div" fontWeight="bold" fontSize='inherit'>
							Quality: 5.0
						</Typography>
						<Typography component="div" fontWeight="bold" fontSize='inherit'>
							Schedule: 4.0
						</Typography>
						<Typography component="div" fontWeight="bold" fontSize='inherit'>
							Cost: 4.5
						</Typography>
						<Typography component="div" fontWeight="bold" fontSize='inherit'>
							Willing to refer: 5.0
						</Typography>
					</Box>
				</Stack> */}

				<Typography variant="h6" component="div" fontWeight="bold" sx={{ mt: 2, mb: 1, textTransform: "uppercase" }}>
					The review
				</Typography>
				<Typography gutterBottom variant="body1" component="div" color="text.secondary" sx={{ mb: 2, }}>
					{props.reviewText}
				</Typography>
				<Typography variant="h6" component="div" fontWeight="bold" sx={{ mb: 1, textTransform: "uppercase" }}>
					Project details
				</Typography>
				<Typography gutterBottom variant="body1" component="div" color="text.secondary">
					{props.projectSummary}
				</Typography>

			</Box>
		</Box>
	);
};

export default StyledCard;
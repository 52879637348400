import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemText,
	MenuItem,
	OutlinedInput,
	TextField,
	Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";

import { useForm, SubmitHandler } from "react-hook-form";
import { AlertColor } from "@mui/material/Alert/Alert";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const projectTypeNames = [
	"Web",
	"Mobile",
	"Design",
	"From scratch",
	"Other",
];

type FormValues = {
	name: string,
	companyName: string,
	email: string,
	projectType: Array<string>,
	message: string,
	prd: boolean,
	file: File | null,
}

type ShowAlertMessage = {
	severity: AlertColor,
	message: string,
}

type ContactFormProps = {
	id: string,
}

const ContactForm = (props: ContactFormProps) => {
	const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<FormValues>({
		defaultValues: {
			name: "",
			companyName: "",
			email: "",
			projectType: [],
			message: "",
			prd: false,
			file: null,
		}
	});

	const [showAlertWithMessage, setShowAlertWithMessage] = useState<ShowAlertMessage | null>(null);
	const [data, setData] = useState<FormValues>();
	const [file, setFile] = useState<File | null>(null);

	const [projectType, setProjectType] = useState<string[]>([]);
	const [projectTypeError, setProjectTypeError] = useState<boolean | null>(null);

	const postContactRequest = async (data: FormValues) => {
		const requestData = new FormData();

		requestData.append("contactRequest", new Blob([JSON.stringify({
			name: data?.name,
			companyName: data?.companyName,
			email: data?.email,
			description: data?.message,
			projectTypes: data?.projectType.map((x) => x.toUpperCase()),
		})], { type: "application/json" }));


		if (data.file !== null) {
			requestData.append("file", data.file);
		}


		await fetch("/api/v1/contact-request", {
			method: "POST",
			body: requestData
		})
			.then((response) => {
				if (!response.ok) {
					setShowAlertWithMessage({
						severity: "warning",
						message: `Contact form hasn't been submitted: ${response.statusText} 
                        try again or reach us trough social network `
					});
					return;
				}
				setShowAlertWithMessage({
					severity: "success",
					message: "Contact form has been submitted"
				});
			})
			.catch((error) => {
				setShowAlertWithMessage({
					severity: "error",
					message: `Contact form hasn't been submitted: ${error.message} 
                               Please contact with us through social network`
				});
			});
	};

	const handleChange = (event: SelectChangeEvent<typeof projectType>) => {
		const {
			target: { value },
		} = event;
		setProjectType(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
		if (value.length) {
			setProjectTypeError(false);
		} else {
			setProjectTypeError(true);
		}
	};


	const onSubmit: SubmitHandler<FormValues> = (formFields: FormValues) => {
		if (projectTypeError === null) {
			setProjectTypeError(true);
			return;
		}
		if (projectTypeError === true) {
			return;
		}

		setData({ ...formFields, projectType, file });
		reset();

		setFile(null);
		setProjectType([]);
		setProjectTypeError(null);
		setValue("prd", true);
	};

	useEffect(function () {
		if (data === undefined) {
			return;
		}

		console.log("Request data: ", { ...data });
		postContactRequest(data);

		setTimeout(() => {
			setShowAlertWithMessage(null);
		}, 10000);
	}, [data]);

	const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement & {
			files: FileList
		};
		setFile(target.files[0]);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={{ xs: 2, md: 3 }} sx={{ m: 2, p: 2 }}>
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<Typography variant="h3" component='div'>Already have an idea?</Typography>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
					<FormControl fullWidth error={!!errors.name}>
						<FormLabel htmlFor={"FName" + props.id}>Name*</FormLabel>
						<TextField id={"FName" + props.id}
							{...register("name",
								{ required: "Name is required" })}
							error={!!errors.name}
							helperText={errors?.name?.message}
							placeholder="Name"
						/>
					</FormControl>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
					<FormControl fullWidth>
						<FormLabel htmlFor={"FCompanyName" + props.id}>Company Name</FormLabel>
						<TextField id={"FCompanyName" + props.id}
							{...register("companyName")}
							placeholder="Company Name"
						/>
					</FormControl>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
					<FormControl fullWidth error={!!errors.email}>
						<FormLabel htmlFor={"FEmail" + props.id}>Email*</FormLabel>
						<TextField id={"FEmail" + props.id}
							{...register("email",
								{ required: "Email is required" })}
							error={!!errors.email}
							helperText={errors?.email?.message}
							placeholder="Email"
						/>
					</FormControl>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
					<FormControl fullWidth error={projectTypeError || false}>
						<FormLabel>Project Type*</FormLabel>
						<Select
							id={"FProjectType" + props.id}
							multiple
							value={projectType}
							onChange={handleChange}
							input={<OutlinedInput />}
							renderValue={(selected) => selected.join(", ")}
							MenuProps={MenuProps}
						// {...register("projectType")}
						>
							{projectTypeNames.map((name) => (
								<MenuItem key={name} value={name}>
									<ListItemText primary={name} />
									<Checkbox checked={projectType.indexOf(name) > -1} />
								</MenuItem>
							))}
						</Select>
						{projectTypeError ? <FormHelperText>Project Type is required</FormHelperText> : null}
					</FormControl>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
					<FormControl fullWidth>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between"
							}}
						>
							<FormLabel htmlFor={"FMessage" + props.id}>Message</FormLabel>

							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>

								{file ?
									<Box
										component='div'
										sx={{
											pointerEvents: "none",
											color: "text.secondary",
										}}
									>
										{file.name}
									</Box> :
									null}
								<FormLabel
									htmlFor={"FormFileInput" + props.id}
									sx={{
										borderRadius: "50%",
										display: "flex",
										alignItems: "center",
									}}
								>

									<AttachFileIcon
										sx={{
											ml: 1,
											transform: "rotate(45deg)"
										}}
										fontSize="large"
									/>
								</FormLabel>
							</Box>

							<input
								type="file"
								id={"FormFileInput" + props.id}
								style={{
									display: "none",
								}}
								onChange={handleFileChange}
							/>
						</Box>

						<TextField
							id={"FMessage" + props.id}
							multiline
							rows={5}
							{...register("message")}
							placeholder="Message"
						/>
					</FormControl>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={6} xs={12}
					sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
					<FormControl fullWidth error={!!errors.prd}>
						<FormGroup>
							<FormControlLabel
								control={<Checkbox
									{...register("prd",
										{ required: true })}
								/>}
								label="I agree to allow Amonika-Soft to store and process my personal data."
								sx={{
									color: "text.secondary"
								}}
							/>
							{errors?.prd ? <FormHelperText>Need your confirmation</FormHelperText> : null}
						</FormGroup>
					</FormControl>
					<FormControl fullWidth>
						{showAlertWithMessage
							? <Alert
								sx={{
									position: "fixed",
									top: "90px",
									left: "5%",
									width: "90%",
									borderRadius: 4
								}}
								severity={showAlertWithMessage.severity}
							>
								{showAlertWithMessage.message}
							</Alert>
							: null}
						<Button
							type="submit"
							className="submit"
							variant="contained"
							size="large"
							sx={{
								backgroundColor: "navbar.button.bg",
								"&:hover": {
									backgroundColor: "navbar.button.hover",
								},
								color: "navbar.button.text",
								borderRadius: 4,
							}}
						>
							Send
						</Button>
					</FormControl>
				</Grid>
			</Grid>
		</form>
	);
};

export default ContactForm;

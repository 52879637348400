import { Box, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";


import cmatrix from "../../assets/cmatrix_fade.png";


const HeroLinks = [
	{
		title: "web",
		href: "/web",
	},
	{
		title: "mobile",
		href: "/mobile",
	},
	{
		title: "design",
		href: "/design",
	},
	{
		title: "devops",
		href: "/devops",
	},
	{
		title: "other",
		href: "/fromscratch",
	},
];

const HeroSection = () => {

	const theme = useTheme();
	const heroBg = theme.palette.mode === "dark" ? "#000" : "#fff";

	return (
		<Box sx={{
			color: "text.primary",
			mb: 2,
			display: "flex",
			flexDirection: {
				xs: "column",
				md: "row"
			},
			justifyContent: "space-around",
			alignItems: "flex-end",

			height: "90vh",
		}}>


			{/* Background matrix image */}
			<Box
				component="img"
				sx={{
					width: "100%",
					height: "100%",
					position: "absolute",
					top: 0,
					left: 0,
					objectFit: "cover",
					zIndex: -2,
					background: `linear-gradient(${heroBg}, rgba(255,255,255,0))`,
				}}
				alt="cmatrix"
				src={cmatrix}
			/>

			<Box
				sx={{
					position: "relative",
					width: { xs: "100%", md: "50%" },
					textAlign: { xs: "center", md: "left" },
					mb: { xs: 0, md: "10%" },
				}}
			>

				<Typography
					variant='h1'
					sx={{ fontSize: { xs: "2.5rem", md: "4.5rem" } }}
					// fontWeight='bold'
				>
					{"{WELCOME TO AMONIKA SOFT}"}
				</Typography>

			</Box>
			<Box
				sx={{
					width: { xs: "100%", md: "50%" },
					display: "flex",
					justifyContent: { xs: "flex-start", md: "flex-end" },
					flexWrap: "wrap",
					mb: { xs: 0, md: "10%" },
				}}
			>

				{
					HeroLinks.map(link => (
						<Link
							key={link.title}
							sx={{
								border: "1px solid",
								borderColor: "text.primary",
								paddingX: 3,
								paddingY: 1,
								borderRadius: 4,
								mt: 2,
								// marginX: 2,
								ml: 2,
							}}
							href={link.href}
							underline="none"
						>
							{link.title}
						</Link>
					))
				}

			</Box>
		</Box>
	);
};

export default HeroSection;
import { Box, Link, Stack, Typography } from "@mui/material";
// import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from "@mui/icons-material/Phone";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { CONTACTS as Contacts } from "../../../Constants";

const ContactSection = () => {
	return (
		<Stack
			direction={{ xs: "column", sm: "row" }}
			justifyContent={"space-between"}
			flexWrap={"wrap"}
		>
			<Box >
				<Link href={`tel:${Contacts.phone}`} underline="none" rel="nofollow">
					<Box
						sx={{ display: "flex", alignItems: "center", mt: 1 }}
					>
						<Typography variant='body1' fontWeight={"bold"}>+3809712345678</Typography>
						{/* <PhoneIcon fontSize='large' sx={{ ml: 1 }} /> */}
					</Box>
				</Link>
				<Link href={`mailto:${Contacts.email}`} underline="none" rel="nofollow">
					<Box
						sx={{ display: "flex", alignItems: "center", mt: 1 }}
					>
						<Typography variant='body1' fontWeight={"bold"}>amonika.soft@gmail.com</Typography>
						{/* <EmailIcon fontSize='large' sx={{ ml: 1 }} /> */}
					</Box>
				</Link>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
				<Link href={`tel:${Contacts.phone}`} underline="none" rel="nofollow">
					<Box
						sx={{ display: "flex", alignItems: "center" }}
					>
						<PhoneIcon fontSize='large' />
					</Box>
				</Link>
				<Link href={Contacts.telegram} underline="none" rel="nofollow" target="_blank">
					<Box
						sx={{ display: "flex", alignItems: "center" }}
					>
						<TelegramIcon fontSize='large' sx={{ ml: 2 }} />
					</Box>
				</Link>
				<Link href={Contacts.whatsapp} underline="none" rel="nofollow" target="_blank">
					<Box
						sx={{ display: "flex", alignItems: "center" }}
					>
						<WhatsAppIcon fontSize='large' sx={{ ml: 2 }} />
					</Box>
				</Link>

			</Box>

		</Stack>
	);
};

export default ContactSection;
import Container from "../../components/Atoms/Container";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Footer } from "../../components/Footer/Footer";
import Navbar from "../Generic/Navbar";
import { Box, Button } from "@mui/material";
import Typography from "../../components/Atoms/Typography";

const LayoutError = () => (
	<div>
		<Container maxWidth={false}
			sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}
			disableGutters>
			<Navbar />
			<Container>
				<Box sx={{
					color: "text.primary",
					mt: 2,
					textAlign: "center"
				}}>
					<Typography
						variant='h3'
						sx={{ fontSize: { xs: "2rem", md: "2rem" }, color: "navbar.text" }}
					>
						The page is not found
					</Typography>
					<Typography
						variant='h3'
						sx={{
							fontSize: {
								xs: "10rem",
								md: "20rem"
							},
							fontWeight: "bold",
							background: "-webkit-linear-gradient( 360deg, #DB93FF, #5DC5FF)",
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
						}}
					>
						404
					</Typography>

					<Button
						variant="outlined"
						href={"/"}
						endIcon={<ArrowForwardIcon />}
						sx={{
							borderRadius: 4,
						}}
					>
						<Typography
							sx={{
								fontSize: { xs: "1rem", md: "1rem" },
								color: "navbar.text",

							}}
						>
							To home page
						</Typography>
					</Button>
				</Box>
			</Container>
			<Footer />
		</Container>
	</div>
);

export default LayoutError;
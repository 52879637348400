import { Helmet } from "react-helmet";
import { PropsWithChildren } from "react";

const CustomHelmet = ({ children, title, description }: PropsWithChildren<{ title: string, description: string }>) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content="react, meta tags, seo" />

			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />

			{children}
		</Helmet>
	);
};

export default CustomHelmet;
import {Box, Container, Typography} from "@mui/material";
import FAQComponent from "./FAQComponent";
import EHelmet from "../../components/SEO/CustomHelmet";
import BackToHomeLink from "../../components/BackToHome/BackToHomeLink";


const FAQ = [
	{
		summary: "What is mobile development, and why is it important for my project?",
		details: "Mobile development refers to the process of creating software applications specifically designed to run on mobile devices such as smartphones and tablets. Mobile development is essential for reaching and engaging with a large and growing audience of mobile users. With the increasing popularity of mobile devices, having a mobile app can enhance user experience, accessibility, and convenience for your target audience. Whether you're launching a new product, extending your brand reach, or enhancing customer engagement, mobile development can play a crucial role in achieving your project goals.",
	},
	{
		summary: "What are the key considerations for mobile development, and how do I choose the right platform for my app?",
		details: `When embarking on a mobile development project, several key considerations come into play, including:
            Target Audience: Identify your target audience and their preferences in terms of mobile devices, operating systems, and usage patterns.
            Platform Selection: Choose the right platform(s) for your app based on factors such as market share, demographics, development resources, and business objectives. Common platforms include iOS (for Apple devices) and Android (for devices running Google's operating system).
            Development Approach: Decide whether to develop a native app (built specifically for a single platform using platform-specific languages and tools), a cross-platform app (compatible with multiple platforms using frameworks like React Native or Flutter), or a web app (accessible via mobile web browsers).
            Features and Functionality: Determine the features and functionality you want to incorporate into your app, keeping in mind user needs, technical feasibility, and project scope.`,
	},
	{
		summary: "What resources and expertise are needed for successful mobile development, and how do I ensure a smooth development process?",
		details: `Successfully executing a mobile development project requires a combination of resources, expertise, and careful planning, including:
            Skilled Development Team: Assemble a team of experienced mobile developers with expertise in the programming languages, frameworks, and platforms relevant to your project (e.g., Swift and Objective-C for iOS, Java and Kotlin for Android).
            Design and User Experience: Incorporate user-centered design principles and best practices to create an intuitive and visually appealing user interface (UI) and user experience (UX) for your app.
            Testing and Quality Assurance: Implement rigorous testing and quality assurance processes to ensure the functionality, performance, and compatibility of your app across different devices, screen sizes, and operating systems.
            Deployment and Maintenance: Plan for app deployment to the respective app stores (e.g., Apple App Store, Google Play Store) and establish processes for ongoing maintenance, updates, and support to address bugs, performance issues, and user feedback.`,
	},
];

const Mobile = () => {
	return (
		<Container>

			<EHelmet title='Amonika-Soft Mobile' description='This is Amonika-Soft Mobile'>
			</EHelmet>

			<BackToHomeLink />

			<Box
				sx={{
					mt: 6,
				}}
			>
				<Typography
					variant='h1'
					sx={{ fontSize: { xs: "3rem", md: "5rem" } }}
				// fontWeight='bold'
				>
					Mobile DEVELOPMENT
				</Typography>
				<Typography
					variant='h6'
					component='div'
					// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
					sx={{
						mt: 2,
					}}
				>
					Mobile development services support the creation of all varieties of browser-related software and provide an exclusive boost-up for further business growth. In order to get the desired degree of adoption rates and up-market conversion, contact Amonika Soft — a professional team of software developers, designers, and web engineers.
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 4,
				}}
			>
				<Typography
					variant='h2'
					component='div'
				// sx={{ fontSize: { xs: '3rem', md: '5rem' } }}
				>
					FAQ
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 2,
				}}
			>
				<FAQComponent FAQ={FAQ} />
			</Box>
		</Container>
	);
};

export default Mobile;
import { Box, Container, Typography } from "@mui/material";
import EHelmet from "../../components/SEO/CustomHelmet";
import BackToHomeLink from "../../components/BackToHome/BackToHomeLink";
import { Footer } from "../../components/Footer/Footer";
import Navbar from "../../layouts/Generic/Navbar";


const PrivacyPolicy = () => {
	return (
		<>
			<Navbar />
			<Container disableGutters={true}>

				<EHelmet title='Amonika-Soft Privacy Policy' description='This is Amonika-Soft Privacy Policy'>
				</EHelmet>

				<BackToHomeLink />

				<Box
					sx={{
						mt: 6,
					}}
				>
					<Typography
						variant='h1'
						sx={{ fontSize: { xs: "3rem", md: "5rem" } }}

					>
						Privacy Policy
					</Typography>

					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						Amonika-Soft, Inc. has created this Privacy Policy in line with Amonika-Soft{"'"}s commitment to
						your
						privacy on Amonika-Soft website and marketing initiatives. The following discloses
						Amonika-Soft{"'"}s
						information gathering, dissemination and protection practices. </Typography>

					<br />
					<Typography
						variant='h5'
					>
						1. Compliance
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						If you do not agree to the terms of this Privacy Policy, please do not access or use
						Amonika-Soft
						website. If you wish to stop receiving Amonika-Soft marketing materials, please click on the
						Unsubscribe button within the marketing email you received from us.
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						2. Collection of information
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						Amonika-Soft collects information to provide better services to its users and better understand
						the
						visitors to its website and what content interests them. Amonika-Soft collects information in
						the
						following ways:
						<ul>
							<li>information you affirmatively choose to submit to us (such as your name, email address,
								company name, title, and country) for the purpose of accessing various downloads,
								viewing
								specific content, subscribing to Amonika-Soft newsletters, registering for events and
								your
								preferences in receiving marketing from Amonika-Soft and its third-party partners and
								your
								communication preferences, etc. Information we have obtained about you from publicly
								available sources such as social media, marketing platforms or on events, you attended.
								You
								will receive marketing communications from Amonika-Soft if you have requested
								information
								from
								Amonika-Soft or you or your employer has purchased services from Amonika-Soft;
							</li>
							<li>information Amonika-Soft receives from your use of Amonika-Soft website, such as the IP
								address,
								browser type, ISP, referring/exit pages, platform type, date/time stamp, number of
								clicks,
								domain name and country/state of all visitors to Amonika-Soft{"'"}s website; location
								information
								when you use Amonika-Soft{"'"}s website as Amonika-Soft may collect and process
								information
								about
								your location. Amonika-Soft uses various technologies and tools to determine location;
							</li>
							<li>information that is considered aggregated data such as statistical or demographic data
								which
								Amonika-Soft may use for any purpose. Aggregated data may be derived from your personal
								information but is not considered personal information in law as it does not directly or
								indirectly reveal your identity unless Amonika-Soft combines or connects aggregated data
								with
								your personal information so that it can directly or indirectly identify you;
							</li>
							<li>Amonika-Soft collects technical data from various third parties and public sources, such
								as:
								analytics providers, advertising networks, search information providers
							</li>
						</ul>
						<p>Other than as required by law, Amonika-Soft website does not collect any special categories
							of
							personal information about you (this includes details about your race or ethnicity,
							religious or
							philosophical beliefs, sex life, sexual orientation, political opinions, trade union
							membership,
							information about your health and genetic and biometric data). Amonika-Soft will not collect
							any
							information about criminal convictions and offences. </p>
						<p> Amonika-Soft will only use your personal information for the purposes for which it was
							collected
							unless it reasonably considers that it needs to use it for another reason that is compatible
							with
							the original purpose. If Amonika-Soft needs to use your personal information for an
							unrelated
							purpose,
							Amonika-Soft will notify you and explain the legal basis which allows Amonika-Soft to do
							so. </p>
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						3. Sharing of Information
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						Amonika-Soft may disclose your personal information to the following categories of recipients:
						<ul>
							<li>to its professional advisers, third-party services providers and partners who provide
								data
								processing services to Amonika-Soft (for example, to support the delivery of, provide
								functionality on, or help to enhance the security of Amonika-Soft website), or who
								otherwise
								process personal information for purposes that are described in Privacy Notices or
								notified
								to you when Amonika-Soft collects your personal information.
							</li>
							<li>to any competent law enforcement body, regulatory, government agency, court or other
								third
								parties where Amonika-Soft believes disclosure is necessary (a) as a matter of
								applicable
								law
								or regulation, (b) to exercise, establish or defend its legal rights, or (c) to protect
								your
								vital interests or those of any other person.
							</li>
							<li>to any other person with your consent to the disclosure.</li>
						</ul>
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						4. Legal Basis of Processing
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						<p> If your personal information is protected by EU data protection law, Amonika-Soft{"'"}s
							legal
							basis for
							collecting and using the personal information described above will depend on the personal
							information concerned and the specific context in which Amonika-Soft collects it.</p>

						<p> However, Amonika-Soft will normally collect personal information from you only (a) where it
							needs
							the
							personal information to perform a contract with you, (b) where the processing is in its
							legitimate
							interests and is not overridden by your rights, or (c) where it has your consent to do so.
							In
							some
							cases, Amonika-Soft may also have a legal obligation to collect personal information from
							you
							or
							may
							otherwise need the personal information to protect your vital interests or those of another
							person.</p>

						<p> If you have questions about or need further information concerning the legal basis on which
							Amonika-Soft collects and uses your personal information, please contact Amonika-Soft using
							the
							contact
							details provided under the {"'"}Contact Details{"'"} heading below and in the right-hand
							side
							column.</p>
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						5. Use of Information
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						<p> Any information collected by Amonika-Soft will be done per this Privacy Policy to make
							better
							business decisions, support the activities you choose to engage in and provide higher
							quality
							solutions and services to Amonika-Soft{"'"}s customers. Amonika-Soft uses your location
							information to
							tailor your experience with Amonika-Soft website. It may also use any non-personal
							information
							collected to enhance Amonika-Soft website, and your IP address for website diagnostics and
							administration. Amonika-Soft will only retain your personal information for as long as
							necessary
							to fulfil the purposes it was collected for, including for the purposes of satisfying any
							legal,
							accounting, or reporting requirements. </p>
						<p> To determine the appropriate retention period for personal information, Amonika-Soft
							considers
							the
							amount, nature, and sensitivity of the personal information, the potential risk of harm from
							unauthorised use or disclosure of your personal information, the purposes for which
							Amonika-Soft
							processes your personal information and whether it can achieve those purposes through other
							means, and the applicable legal requirements.</p>
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						6. Information Security
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						Amonika-Soft protects your personal information in the same way that it protects its other
						confidential information, such as storing it on secure servers that only select Amonika-Soft
						personnel have access to, and encrypting it to help prevent access by any unauthorized parties.
						Amonika-Soft has put in place procedures to deal with any personal information breach and will
						notify you and any applicable regulator of a breach when legally required to do so.
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						7. Marketing
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						As explained above, Amonika-Soft will collect and use your personal data for marketing purposes.
						Your data will be stored in our CRM system. We will use your data to inform you about
						Amonika-Soft
						products and services we think may be of your interest and send you our newsletters. For
						statistical purposes, we will collect information about your response to our emails and our
						interaction with you to make decisions on future marketing. If you wish to stop that we collect
						and use this information please contact admin@redrocket.software.
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						8. Links
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						Amonika-Soft website contains links, plug-ins and applications to various third-party websites.
						Clicking on those links or enabling those connections may allow third parties to collect or
						share data about you. Amonika-Soft has no control over and is not responsible for the content,
						privacy policies or reliability of such third-party connections. When you leave
						Amonika-Soft{"'"}s
						website, Amonika-Soft encourages you to read the privacy notice and privacy policy of every
						website you visit.
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						9. Your Data Protection Rights
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						f your personal information is protected by EU data protection law, you have the following data
						protection rights which you may be able to exercise by contacting Amonika-Soft using the contact
						details provided under the {"'"}Contact Details{"'"} heading below:
						<ul>
							<li>If you wish to access, correct, update or request the deletion of your personal
								information, you can do so at any time by contacting Amonika-Soft.
							</li>
							<li>In addition, you can object to the processing of your personal information, ask
								Amonika-Soft to restrict the processing of your personal information or request
								portability of your personal information by contacting Amonika-Soft.
							</li>
							<li>You have the right to opt out of marketing communications that Amonika-Soft sends you at
								any time. You can exercise this right by clicking on
								the {"'"}unsubscribe{"'"} or {"'"}opt-out{"'"} link in the marketing emails Amonika-Soft
								sends you or by using the relevant opt-out mechanism for non-email-based marketing.
							</li>
							<li>Similarly, if Amonika-Soft has collected and processed your personal information based
								on your consent, then you can withdraw your consent at any time. Withdrawing your
								consent will not affect the lawfulness of any processing Amonika-Soft conducted prior to
								your withdrawal, nor will it affect the processing of your personal information
								conducted in reliance on lawful processing grounds other than consent.
							</li>
							<li>You have the right to complain to a data protection authority about Amonika-Soft{"'"}s
								collection and use of your personal information. For more information, please contact
								your local data protection authority.
							</li>
						</ul>
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						10. Changes to the Policy
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						<p>Amonika-Soft reserves the right to modify or amend this Privacy Policy at its own discretion
							from
							time to time. The changes can be viewed here or elsewhere on Amonika-Soft website. Your
							continued
							use of Amonika-Soft websites constitutes your acceptance of those changes.</p>
						<p> This version was last updated on May 19, 2023, and historic versions can be obtained by
							contacting Amonika-Soft.</p>
					</Typography>
					<br />
					<Typography
						variant='h5'
					>
						11. Contact Details
					</Typography>
					<Typography
						variant='h6'
						component='div'
						// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
						sx={{
							mt: 2,
						}}
					>
						It is important that the personal information Amonika-Soft holds about you is accurate and
						current. Please keep Amonika-Soft informed if your personal information changes during your
						relationship with Amonika-Soft. Additionally, if you have any questions about this Privacy
						Policy,
						the practices of Amonika-Soft website or your interaction with Amonika-Soft via Amonika-Soft
						website,
						please contact:

						Email:
						contact-team@amonika-soft.com
					</Typography>
				</Box>

			</Container>
			<Footer />
		</>
	);
};

export default PrivacyPolicy;

import { Box, Button, Dialog, DialogContent, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContactForm from "../CotactForm";
import { useState } from "react";
import { useIsMobile } from "../../../components/Util/Util";

const ContactFormModalWithButton = () => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<Button
				sx={{
					backgroundColor: "navbar.button.bg",
					"&:hover": {
						backgroundColor: "navbar.button.hover",
					},
					color: "navbar.button.text",
					borderRadius: 4,
					textTransform: "none",
					paddingX: 2,
					fontSize: "1rem",
					whiteSpace: "nowrap"
				}}
				onClick={handleOpen}
			>
				Contact Us
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				fullScreen={useIsMobile()}
				fullWidth
				maxWidth="lg"
			>
				<DialogContent
					sx={{
						backgroundColor: "contactForm.bgModal",
					}}
				>
					{/* Background circles */}
					<Box
						sx={{
							backgroundColor: "contactForm.buble",
							opacity: 0.5,

							fontSize: {
								xs: "90px",
								md: "150px"
							},
							height: "2em",
							width: "2em",
							filter: "blur(72px)",

							position: "absolute",
							top: { xs: "0em", md: "0em" },
							right: { xs: "0em", md: "0em" },
							// zIndex: 0,
							borderBottomLeftRadius: "100%",
						}}
					>
					</Box>
					<Box
						sx={{
							backgroundColor: "contactForm.buble",
							opacity: 0.5,

							fontSize: {
								xs: "100px",
								md: "150px"
							},
							height: "2em",
							width: "2em",
							filter: "blur(72px)",

							position: "absolute",
							bottom: { xs: "0em", md: "0em" },
							left: { xs: "0em", md: "0em" },
							// zIndex: 0,
							borderTopRightRadius: "100%",
						}}
					>
					</Box>

					<Stack
						alignItems="flex-end"
					>
						<IconButton
							edge="end"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
					</Stack>
					<ContactForm id="baseForm" />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default ContactFormModalWithButton;

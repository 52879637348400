import { Box, Container, Grid, Link, Typography } from "@mui/material";
import Card from "./Card";
import CallMadeIcon from "@mui/icons-material/CallMade";


const CardsInformation = [
	{
		title: "Web",
		description: "Corporate websites, marketplaces, e-commerce, platforms",
		link: "/web",
	},
	{
		title: "Mobile",
		description: "iOS, Android, cross-platform dev",
		link: "/mobile",
	},
	{
		title: "Design",
		description: "UI/UX Design, product design, logo, wireframing, prototyping",
		link: "/design",
	},
	{
		title: "From Scratch",
		description: "Product from scratch - design thinking, idea validation, discovery phase, customer development, business model, MVP, metrics & analytics",
		link: "/fromscratch",
	},
	{
		title: "DevOps",
		description: "Version control, implement and integrate source control, manage build infrastructure, application configuration",
		link: "/devops",
	},
];

const CardBlock = () => {
	return (
		<Container disableGutters>
			<Grid container spacing={2} direction={"row-reverse"}
			>
				<Grid item xl={4} lg={4} md={4} sm={6} xs={12}
					display={{ xs: "none", sm: "flex" }}
					sx={{ alignItems: "center", justifyContent: "center" }}>
					<Link href="#" underline="none">
						<Box
							sx={{ display: "flex", alignItems: "center" }}
						>
							<Typography variant='h3'>Features</Typography>
							<CallMadeIcon fontSize='large' sx={{ ml: 1 }} />
						</Box>
					</Link>
				</Grid>
				{
					CardsInformation.map(card => (
						<Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={card.title} >
							<Link href={card.link} underline="none" >
								<Card {...card} />
							</Link>
						</Grid>
					))
				}
			</Grid>

		</Container>
	);
};

export default CardBlock;
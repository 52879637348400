import { Box, Container } from "@mui/material";
import Typography from "../components/Atoms/Typography";

import HeroSection from "./Home/HeroSection";
import FeatureCardBlock from "../components/FeatureCards/CardBlock";
import AboutUsSection from "./Home/AboutUsSection";
import ReviewCardBlock from "../components/ReviewCards/CardBlock";
import EHelmet from "../components/SEO/CustomHelmet";


const Home = () => {
	return (
		<Container>

			<EHelmet title='Amonika-Soft Homepage' description='This is Amonika-Soft Homepage'>
			</EHelmet>

			<HeroSection />

			<Container disableGutters>
				<Box id={"features"}>
					<FeatureCardBlock />
				</Box>
			</Container>

			<Box id={"about-us"}>
				<AboutUsSection />
			</Box>

			<Container
				disableGutters
				sx={{
					mt: 2,
					mb: {
						xs: 0,
						md: 16,
					},
				}}
			>
				<Box id={"reviews"}>
					<Typography
						variant='h2'
						sx={{
							mb: 2,
							ml: 2
						}}
					>
						Reviews
					</Typography>
					<ReviewCardBlock />
				</Box>
			</Container>
		</Container>
	);
};

export default Home;
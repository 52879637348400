import { Box, Container } from "@mui/material";
import ContactForm from "../CotactForm";
import ContactSection from "./ContactSection";

const FormContactUs = () => {
	return (
		<Container sx={{ mt: 2 }}>
			<Box
				sx={{
					backgroundColor: "background.paper",
					mt: 7,
					borderRadius: 2,
					color: "shadows.card",
					boxShadow: "0 0px 30px",
				}}
			>
				<ContactForm id="modalForm" />
			</Box>
			<Box
				sx={{
					mt: 2,
				}}
			>
				<ContactSection />
			</Box>
		</Container>
	);
};

export default FormContactUs;

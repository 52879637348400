import { Box, Typography } from "@mui/material";
import { MaterialUISwitch } from "../../../components/Switch/Switch";
import ContactFormModalWithButton from "../../Main/ContactFormModalWithButton";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { ColorModeContext } from "../../../Theme";
import LogoWithTitle from "./LogoWithTitle";
import { HashLink as Link } from "react-router-hash-link";

const DesktopMenu = () => {
	const theme = useTheme();
	const colorMode = useContext(ColorModeContext);
	return (
		<>
			<LogoWithTitle />
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "30px",
					color: "navbar.text",
				}}
			>
				<Link
					style={{ color: "inherit", textDecoration: "none" }}
					scroll={(el) => {
						el.scrollIntoView({ behavior: "smooth", block: "center" });
					}}
					to={"/#features"}
				>
					<Typography variant="h6" component="div">
						Features
					</Typography>
				</Link>
				<Link
					style={{ color: "inherit", textDecoration: "none" }}
					scroll={(el) =>
						el.scrollIntoView({ behavior: "smooth", block: "center" })
					}
					to={"/#about-us"}
				>
					<Typography variant="h6" component="div">
						About
					</Typography>
				</Link>
				<Link
					style={{ color: "inherit", textDecoration: "none" }}
					scroll={(el) =>
						el.scrollIntoView({ behavior: "smooth", block: "center" })
					}
					to={"/#reviews"}
				>
					<Typography variant="h6" component="div">
						Reviews
					</Typography>
				</Link>

				<MaterialUISwitch
					checked={theme.palette.mode === "dark"}
					onChange={colorMode.toggleColorMode}
					inputProps={{ "aria-label": "controlled" }}
				/>
				<ContactFormModalWithButton />
			</Box>
		</>
	);
};

export default DesktopMenu;

import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type FAQ1Props = {
	summary: string,
	details: string,
}

type FAQProps = {
	FAQ: FAQ1Props[]
}

const FAQComponent = ({ FAQ }: FAQProps) => {
	return (
		<>
			{
				FAQ.map((faq, index) => (
					<Accordion key={index}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel${index}-content`}
							id={`panel${index}-header`}
						>
							<Typography
								component='div'
								color='text.primary'
							>
								{faq.summary}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component='div'
								color='text.secondary'
								sx={{
									whiteSpace: "pre-line"
								}}
							>
								{faq.details}
							</Typography>
						</AccordionDetails>
					</Accordion>
				))
			}
		</>
	);
};

export default FAQComponent;

export const defaultFAQ = [
	{
		summary: "What steps are involved in the web development process?",
		details: `Our process typically involves several key steps to ensure the successful completion of your project:
    Discovery and Planning => Design => Development => Testing and Quality Assurance => Deployment => Launch and Optimization => Maintenance and Support
    Throughout the entire process, we prioritize communication and collaboration to keep you informed and involved every step of the way. Our goal is to deliver a high-quality product that exceeds your expectations and helps you achieve your business objectives.`,
	},
	{
		summary: "How long should I wait?",
		details: `We understand the importance of timelines in web development projects and strive to provide realistic estimates to our clients. The duration of your project can vary depending on several factors, including its complexity, scope, and any specific requirements you may have.
    Typically, we begin by conducting a thorough assessment of your project needs and requirements. Following this initial evaluation, we'll provide you with an estimated timeline for completion. This timeline will take into account factors such as design, development, testing, and deployment.
    Ultimately, our goal is to deliver a high-quality, fully functional website or web application that meets your requirements and exceeds your expectations, within a reasonable timeframe.`,
	},
	{
		summary: "How much will my web development project cost?",
		details: `We understand that cost is an important factor for our clients when considering a web development project. The cost of your project can vary depending on a variety of factors, including the complexity of the design, the functionality required, and any specific features or integrations you may need.
    Additionally, we understand that budgets can vary, so we offer flexible payment options and can tailor our services to meet your financial constraints.
    Rest assured, our goal is to deliver exceptional value and ensure that you receive the best possible return on your investment.`,
	},
];
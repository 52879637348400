import { Container, Grid } from "@mui/material";
import Card from "./Card";

import avatarWoman from "../../assets/avatarWoman.jpg";
import avatarMan from "../../assets/avatarMan.jpg";

const CardsInformation = [
	{
		reviewer: "CEO, Mealify Company",
		project: "Mealify",
		feedbackSummary: "Amonika-Soft proudly announces ongoing support and hosting services for Mealify, ensuring its seamless operation and uninterrupted availability to users. ",
		companyName: "Mealify",
		projectName: "Website for Personalized Meal Planning Platform",
		projectDate: "Jan. - Feb. 2024",
		projectSummary: "Mealfy's implementation encompasses a multifaceted approach focusing on user experience, functionality, and scalability. Key components include frontend development for an intuitive web interface, backend systems for personalized meal plan generation and recipe management and mobile responsiveness for accessibility across devices.",
		reviewText: "\"I want to express my gratitude for your outstanding work in creating the Mealify platform. Your dedication and expertise have been instrumental in bringing our vision to life. I am truly impressed with the quality of the website and the functionality it offers to our users.\"",
		avatarSrc: avatarWoman
	},
	{
		reviewer: "CEO, FitBuddy",
		project: "FitBuddy",
		feedbackSummary: "Amonika-soft has successfully completed all requirements outlined for the project, delivering a robust and user-friendly fitness platform that meets specifications.",
		companyName: "FitBuddy",
		projectName: "Personalized Fitness Coaching Platform",
		projectDate: "Feb. - Apr. 2024",
		projectSummary: "Leveraging our expertise in frontend and backend development, we have successfully implemented features such as personalized workout plans, video demonstrations, progress tracking tools, and community engagement features.",
		reviewText: "\"You've been proactive in addressing any challenges that arose and have always been receptive to feedback, ensuring that the final product meets our requirements and surpasses our expectations.\"",
		avatarSrc: avatarMan
	},
];

export type ReviewCardProps = {
	reviewer: string,
	project: string,
	feedbackSummary: string,
	companyName: string,
	projectName: string,
	projectDate: string,
	projectSummary: string,
	reviewText: string,
	avatarSrc: string
}

const CardBlock = () => {
	return (
		<Container disableGutters>
			<Grid container spacing={2}
			>
				{
					CardsInformation.map(card => (
						<Grid item xl={6} lg={6} md={6} sm={6} xs={12} key={card.reviewer} >
							<Card {...card} />
						</Grid>
					))
				}
			</Grid>

		</Container>
	);
};

export default CardBlock;
import { Box, Container, Link, Stack, SvgIcon, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ReactComponent as LightThemeLogo } from "../../assets/AmonikaLightThemeLogo.svg";
import { ReactComponent as DarkThemeLogo } from "../../assets/AmonikaDarkThemeLogo.svg";

import { CONTACTS as Contacts } from "../../Constants";
import { useTheme } from "@mui/material/styles";

export const Footer = () => {
	const theme = useTheme();

	return (
		<Container
			sx={{
				backgroundColor: "navbar.bg",
				mt: 4,
			}}
			maxWidth={false}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: {
						xs: "column",
						sm: "row"
					},
					justifyContent: "space-between"
				}}
			>
				<Stack sx={{ marginY: 2 }}>
					<Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
						{theme.palette.mode === "dark" ? <DarkThemeLogo /> : <LightThemeLogo />}
						<Typography sx={{ color: "navbar.text" }} variant="h6" fontWeight={"bold"} component="div">
							Amonika
						</Typography>
					</Box>
					<Typography>
						© Amonika-Soft Inc.
					</Typography>
					<Typography>
						2024 All rights reserved
					</Typography>
				</Stack>
				<Stack sx={{ marginY: 2 }}>
					<Box
						sx={{ display: "flex" }}
					>
						<Typography variant='body1' fontWeight={"bold"} sx={{ mr: 2 }}>Follow us </Typography>
						<Link href={Contacts.gitHub} underline="none" rel="nofollow" sx={{ mr: 2 }} target="_blank">
							<SvgIcon>
								<GitHubIcon />
							</SvgIcon>
						</Link>
						<Link href={Contacts.facebook} underline="none" rel="nofollow" sx={{ mr: 2 }} target="_blank">
							<SvgIcon>
								<FacebookOutlinedIcon />
							</SvgIcon>
						</Link>
						<Link href={Contacts.instagram} underline="none" rel="nofollow" sx={{ mr: 2 }} target="_blank">
							<SvgIcon>
								<InstagramIcon />
							</SvgIcon>
						</Link>
						<Link href={Contacts.linkedIn} underline="none" rel="nofollow" target="_blank">
							<SvgIcon>
								<LinkedInIcon />
							</SvgIcon>
						</Link>
					</Box>
					<Box
						sx={{
							textAlign: {
								xs: "left",
								sm: "right",
							}
						}}
					>
						<Link href={"/privacy/policy"} underline="none" rel="nofollow">
							<Typography>
								Privacy Policy
							</Typography>
						</Link>
						<Link href={"/privacy/terms-of-use"} underline="none" rel="nofollow">
							<Typography>
								Terms of use
							</Typography>
						</Link>
					</Box>
				</Stack>
			</Container>
		</Container>
	);
};
import {Box, Container, Typography} from "@mui/material";
import FAQComponent from "./FAQComponent";
import EHelmet from "../../components/SEO/CustomHelmet";
import BackToHomeLink from "../../components/BackToHome/BackToHomeLink";


const FAQ = [
	{
		summary: "What is design development, and how does it contribute to the success of my project?",
		details: "Design development is the process of refining and enhancing the visual and functional aspects of a project's design. It involves translating design concepts into tangible assets, such as user interfaces, graphics, animations, and interactive elements. Design development plays a crucial role in shaping the overall user experience (UX) and user interface (UI) of a project, contributing to its usability, aesthetics, and brand identity. By investing in design development, you can create engaging, intuitive, and visually appealing experiences that resonate with your target audience, differentiate your brand, and drive user engagement and satisfaction.",
	},
	{
		summary: "What are the key considerations for design development, and how do I ensure the final design aligns with my project goals?",
		details: `When incorporating design development features into your project, consider the following key considerations:
            User-Centered Design: Prioritize the needs, preferences, and behaviors of your target audience when designing user interfaces and interactions. Conduct user research, usability testing, and iterative design cycles to gather feedback and refine your designs based on user insights.
            Brand Identity and Visual Consistency: Ensure that your design elements, such as colors, typography, imagery, and branding assets, are aligned with your brand identity and guidelines. Maintain visual consistency across all touchpoints to reinforce brand recognition and credibility.
            Accessibility and Inclusivity: Design with accessibility in mind to ensure that all users, including those with disabilities, can access and interact with your project's content and features. Adhere to web accessibility standards and guidelines, such as WCAG (Web Content Accessibility Guidelines), to create inclusive and barrier-free experiences.
            Scalability and Responsiveness: Design responsive and adaptive layouts that can adapt to different screen sizes, devices, and resolutions. Consider the scalability of your design elements and ensure they remain visually appealing and functional across various contexts and platforms.`,
	},
	{
		summary: "What resources and expertise are needed for successful design development, and how do I collaborate effectively with designers and developers?",
		details: `Successfully executing design development requires a combination of resources, expertise, and collaborative efforts, including:
            Skilled Design and Development Team: Assemble a multidisciplinary team of experienced designers, developers, and project managers with expertise in UX/UI design, graphic design, front-end development, and interaction design. Foster collaboration and communication between designers and developers to ensure alignment and synergy between design and development efforts.
            Design Tools and Software: Equip your team with the necessary design tools and software, such as Adobe Creative Suite, Sketch, Figma, or Adobe XD, to create, iterate, and prototype design concepts effectively. Invest in training and professional development to ensure team members are proficient in using these tools.
            Iterative Design Process: Embrace an iterative design process that involves prototyping, testing, and iterating on design concepts based on user feedback and insights. Encourage a culture of experimentation and continuous improvement to refine and enhance the design over time.
            Stakeholder Collaboration: Involve stakeholders, such as clients, users, and subject matter experts, in the design development process to gather feedback, validate design decisions, and ensure alignment with project goals and requirements. Establish clear communication channels and feedback loops to facilitate effective collaboration and decision-making.`,
	}
];

const Design = () => {
	return (
		<Container>

			<EHelmet title='Amonika-Soft Design' description='This is Amonika-Soft Design'>
			</EHelmet>

			<BackToHomeLink />

			<Box
				sx={{
					mt: 6,
				}}
			>
				<Typography
					variant='h1'
					sx={{ fontSize: { xs: "3rem", md: "5rem" } }}
				// fontWeight='bold'
				>
					Design DEVELOPMENT
				</Typography>
				<Typography
					variant='h6'
					component='div'
					// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
					sx={{
						mt: 2,
					}}
				>
					Design development services support the creation of all varieties of browser-related software and provide an exclusive boost-up for further business growth. In order to get the desired degree of adoption rates and up-market conversion, contact Amonika Soft — a professional team of software developers, designers, and web engineers.
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 4,
				}}
			>
				<Typography
					variant='h2'
					component='div'
				// sx={{ fontSize: { xs: '3rem', md: '5rem' } }}
				>
					FAQ
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 2,
				}}
			>
				<FAQComponent FAQ={FAQ} />
			</Box>
		</Container>
	);
};

export default Design;
import {Box, Container, Typography} from "@mui/material";
import FAQComponent from "./FAQComponent";
import EHelmet from "../../components/SEO/CustomHelmet";
import BackToHomeLink from "../../components/BackToHome/BackToHomeLink";


const FAQ = [
	{
		summary: "How can implementing DevOps practices benefit my development team and project?",
		details: `DevOps practices offer several benefits to development teams and projects, including:
            Faster Time-to-Market: DevOps promotes automation and collaboration, leading to shorter development cycles and faster delivery of features and updates.
            Improved Quality and Reliability: By integrating automated testing, continuous integration, and continuous deployment practices, DevOps helps identify and address issues earlier in the development process, resulting in higher-quality software and reduced defects.
            Enhanced Collaboration and Communication: DevOps fosters a culture of collaboration and communication between development, operations, and other stakeholders, leading to smoother workflows, fewer bottlenecks, and improved alignment with business objectives.
            Increased Agility and Flexibility: DevOps practices enable teams to respond quickly to changing requirements, market trends, and customer feedback, allowing for more agile and adaptive development processes.`,
	},
	{
		summary: "What are the key steps involved in implementing DevOps practices within my organization?",
		details: `Implementing DevOps practices requires a strategic approach and may involve the following key steps:
            Assess Current Processes: Evaluate existing development, testing, deployment, and operations processes to identify areas for improvement and opportunities for automation.
            Foster a DevOps Culture: Encourage collaboration, transparency, and shared responsibility across development, operations, and other teams. Promote a culture of continuous learning, experimentation, and innovation.
            Automate Processes: Implement automation tools and frameworks for tasks such as code integration, testing, deployment, and infrastructure provisioning. Streamline workflows and eliminate manual, error-prone tasks wherever possible.
            Establish Metrics and Monitoring: Define key performance indicators (KPIs) and establish monitoring and logging mechanisms to track the effectiveness of DevOps practices and identify areas for optimization.
            Continuous Improvement: Embrace a mindset of continuous improvement and iteration. Encourage feedback from team members and stakeholders, and regularly review and refine processes to enhance efficiency, quality, and agility.`,
	},
	{
		summary: "What resources and expertise are needed to successfully adopt DevOps practices?",
		details: `Successful adoption of DevOps practices requires a combination of resources, expertise, and organizational support, including:
            Skilled Personnel: Invest in hiring or training personnel with expertise in DevOps principles, automation tools, and cloud technologies. Foster a culture of learning and development to keep skills up-to-date.
            Automation Tools and Infrastructure: Implement appropriate automation tools and infrastructure to support continuous integration, deployment, testing, and monitoring processes. Choose tools that align with your organization's technology stack and requirements.
            Leadership and Organizational Support: Secure buy-in and support from senior leadership and stakeholders to drive cultural and organizational change. Establish clear goals, priorities, and expectations for adopting DevOps practices, and allocate resources accordingly.
            Collaboration and Communication: Encourage open communication and collaboration between development, operations, and other teams. Break down silos and foster cross-functional teams to promote shared ownership and accountability.`,
	},
];

const DevOps = () => {
	return (
		<Container>

			<EHelmet title='Amonika-Soft DevOps' description='This is Amonika-Soft DevOps'>
			</EHelmet>

			<BackToHomeLink />

			<Box
				sx={{
					mt: 6,
				}}
			>
				<Typography
					variant='h1'
					sx={{ fontSize: { xs: "3rem", md: "5rem" } }}
				// fontWeight='bold'
				>
					DevOps DEVELOPMENT
				</Typography>
				<Typography
					variant='h6'
					component='div'
					// sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
					sx={{
						mt: 2,
					}}
				>
					DevOps development services support the creation of all varieties of browser-related software and provide an exclusive boost-up for further business growth. In order to get the desired degree of adoption rates and up-market conversion, contact Amonika Soft — a professional team of software developers, designers, and web engineers.
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 4,
				}}
			>
				<Typography
					variant='h2'
					component='div'
				// sx={{ fontSize: { xs: '3rem', md: '5rem' } }}
				>
					FAQ
				</Typography>
			</Box>

			<Box
				sx={{
					mt: 2,
				}}
			>
				<FAQComponent FAQ={FAQ} />
			</Box>
		</Container>
	);
};

export default DevOps;
export const CONTACTS = {
	email: "amonika.soft@gmail.com",
	phone: "+359877994228",
	telegram: "https://t.me/amonikasoft",
	whatsapp: "https://wa.me/message/U3UYTVVZVHGPC1",
	gitHub: "https://github.com/Amonika-Soft",
	facebook: "https://www.facebook.com/profile.php?id=61555664065043",
	instagram: "https://www.instagram.com/amonika.soft/",
	linkedIn: "https://www.linkedin.com/company/amonika-soft",
};
